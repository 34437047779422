var CookiePolicy = function () {
    if (!window.jQuery) throw new Error('CookiePolicy requires jQuery');
    var _this = this;

    //Code to get domain without subdomains
    var url = window.location.hostname;
    var currentdomain = url.split(".");
    currentdomain = currentdomain.slice(0).slice(-(currentdomain.length === 4 ? 3 : 2));
    currentdomain = currentdomain.join(".");

    config = {
        //template: '<div id="cookieBanner" class="hidden-print hidden"><p><strong>$TITLE$:</strong> We have placed cookies on your device to ensure this website works as expected and to help us improve this website. Please refer to our $PP$ for details.</p><p>We’ll assume you’re OK to continue, otherwise please disable cookies in your browser settings.<a href="#" data-close class="btn btn-info btn-xs inline-block btn-close" >OK, I understand</a></p></div>',
        //<h3>$TITLE$</h3>
        template: `<div id="cookieBanner" class="hidden-print hidden">
            <div class="pull-right">
                $PP$ 
                <a class="btn btn-info btn-xs inline-block btn-close" href="#" data-close="">OK, I understand</a>
            </div>
            <p>This website places some necessary cookies on your device.<br> These cookies are purely functional to ensure this website works as expected and to help us improve this website.<br>If you do not agree, please disable cookies in your browser settings, otherwise please click Ok to continue.</p>
        </div>`,
        cssUrl: '',
        privacyPolicyUrl: '',
        title: 'Cookie Notice',
        expiryDays: 365
    }
    this.load = function (config) {
        if (_this.isHidden()) return;

        $(document).ready(function () {
            _this.config = $.extend({}, _this.config, config);
            _this.loadCSS().setTitle().setPrivacyPolicy().addTemplate().addEvents();
        });
        return _this;
    }
    this.setTitle = function () {
        _this.config.template = _this.config.template.replace('$TITLE$', _this.config.title);
        return _this;
    }
    this.setPrivacyPolicy = function () {
        var PP = (_this.config.privacyPolicyUrl) ? '<a class="btn btn-info btn-xs inline-block btn-close" href="' + _this.config.privacyPolicyUrl + '">See details</a>' : 'Privacy Policy';
        _this.config.template = _this.config.template.replace('$PP$', PP);
        return _this;
    }
    this.loadCSS = function () {
        if (_this.config.cssUrl) {
            $("<link/>", {
                rel: "stylesheet",
                type: "text/css",
                href: _this.config.cssUrl
            }).appendTo("head");
        }
        return _this;
    }
    this.addTemplate = function () {
        _this.cpTemplate = $(_this.config.template);
        $('body').append(_this.cpTemplate);
        window.setTimeout(function () { _this.cpTemplate.removeClass('hidden') }, 2000);
        return _this;
    }
    this.addEvents = function () {
        _this.cpTemplate.on('click', '[data-close]', function (e) {
            e.preventDefault();
            _this.cpTemplate.addClass('hidden');
            window.setTimeout(function () { _this.cpTemplate.remove() }, 2000);
            _this.remember();
        });
        return _this;
    }
    this.remember = function () {
        var expires = "";
        if (_this.config.expiryDays) {
            var date = new Date();
            date.setTime(date.getTime() + (_this.config.expiryDays * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }

        document.cookie = 'CookiePolicyHidden=true' + expires + "; path=/; domain=" + currentdomain + "; Secure";
    }
    this.isHidden = function () {
        var nameEQ = "CookiePolicyHidden=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) {
                var value = c.substring(nameEQ.length, c.length);
                return /true/.test(value);
            }
        }
    }
    return this;

}

if ($('#cookieData').length) {
    var data = $('#cookieData').data();

    CookiePolicy().load({
        title: 'Cookies',
        privacyPolicyUrl: data.policy,
        cssUrl: data.hostname + '/Content/CookiePolicy/Banner.min.css'
    });
}